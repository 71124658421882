<template>
  <div>
    <b-card>
      <filter-data
        :search-data="searchData"
        :detail-search-data="detailSearchData"
      />
    </b-card>
    <b-card no-body>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(company)="data">
          <b-link
            class="text-body"
            :to="$route.path + '/view/' + data.item.id"
          >
            {{ data.item.company }}
          </b-link>
        </template>
        <template #cell(interview_subject)="data">
          <div
            v-if="data.item.brand"
            class="text-success font-small-2 font-weight-bold"
          >
            {{ data.item.brand }}
          </div>
          {{ data.item.interview_subject }}
          <div
            v-if="data.item.meet"
            class="text-primary font-small-2"
          >
            {{ data.item.meet }}
          </div>
        </template>
        <template #cell(interview_status)="data">
          {{ data.item.interview_status }}
          <div
            class="font-small-2"
            :class="data.item.status === '1'? 'text-danger' : 'text-success'"
          >
            {{ data.item.status === '1'? 'Kapalı Görüşme' : 'Açık Görüşme' }}
          </div>
          <div
            v-if="data.item.username || data.item.dealer_user"
            class="text-muted font-small-2"
          >
            {{ data.item.username? data.item.username : data.item.dealer_user }}
            <div
              v-if="data.item.dealer_user"
              class="font-small-2 text-muted"
            >
              (Bayi Kullanıcısı)
            </div>
          </div>
        </template>
        <template #cell(control)="data">
          <div class="text-right">
            <template v-if="userData.id_dealers">
              <div v-if="userData.id_dealers === data.item.id_dealers">
                <list-buttons
                  primary-text="Görüntüle"
                  :primary-action="$route.path + '/view/' + data.item.id"
                  :edit-action="$route.path + '/edit/' + data.item.id"
                  :delete-action="removeData"
                  :data-id="data.item.id"
                />
              </div>
              <div v-else>
                <b-button
                  variant="info"
                  block
                  size="sm"
                  :to="$route.path + '/view/' + data.item.id"
                >
                  <FeatherIcon icon="EyeIcon" />
                  Görüntüle
                </b-button>
              </div>
            </template>
            <template v-else>
              <list-buttons
                primary-text="Görüntüle"
                :primary-action="$route.path + '/view/' + data.item.id"
                :delete-action="removeData"
                :data-id="data.item.id"
              />
            </template>
          </div>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BTable, BPagination, BCardFooter, BLink, BButton,
} from 'bootstrap-vue'
import FilterData from '@/views/Admin/Interviews/Index/FilterData.vue'
import ListButtons from '@/layouts/components/common/ListDropdownButtons.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const tableName = 'interviews'
export default {
  name: 'Index',
  components: {
    BButton,
    BCard,
    BTable,
    BPagination,
    BCardFooter,
    BLink,
    FilterData,
    ListButtons,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'company',
          label: 'FİRMA ADI',
          thClass: 'w-auto',
        },
        {
          key: 'interview_subject',
          label: 'KONU',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
        },
        {
          key: 'interview_status',
          label: 'DURUM',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          thStyle: { width: '180px' },
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          'customers.company AS company',
          'interview_statuses.title AS interview_status',
          'interview_statuses.status AS status',
          'interview_subjects.title AS interview_subject',
          'meets.title AS meet',
          'brands.title AS brand',
          'users.name AS username',
          'dealer_users.name AS dealer_user',
          'dealer_users.id_dealers AS id_dealers',
        ],
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['interviews/dataList']
    },
    dataCounts() {
      return this.$store.getters['interviews/dataCounts']
    },
    saveData() {
      return this.$store.getters['interviews/dataSaveStatus']
    },
    search() {
      return this.$store.getters['interviews/search']
    },
    filterData() {
      return this.$store.getters['interviews/filterData']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      if (this.search.keyword) {
        this.dataQuery.or_like = {
          'customers.company': this.search.keyword,
          'customers.phone': this.search.keyword,
        }
      } else {
        this.dataQuery.or_like = {}
      }
      this.getDataList()
    },
    detailSearchData() {
      const where = {}
      if (this.filterData.sdate) {
        where['interviews.created >='] = this.filterData.sdate
      }
      if (this.filterData.edate) {
        where['interviews.created <='] = this.filterData.edate
      }
      if (this.filterData.id_interview_subjects) {
        where['interviews.id_interview_subjects'] = this.filterData.id_interview_subjects
      }
      if (this.filterData.id_brands) {
        where['interviews.id_brands'] = this.filterData.id_brands
      }
      if (this.filterData.status) {
        where['interview_statuses.status'] = this.filterData.status
      }
      if (this.filterData.id_meets) {
        where['interviews.id_meets'] = this.filterData.id_meets
      }
      if (this.filterData.id_users) {
        where['interviews.id_users'] = this.filterData.id_users
      }
      this.dataQuery.where = where
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('interviews/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('interviews/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
