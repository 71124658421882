<template>
  <b-form-group
    label="Marka"
    label-for="id_brands"
  >
    <v-select
      v-model="filterData.id_brands"
      :options="brands"
      label="title"
      :reduce="item => item.id"
      placeholder="Seçiniz"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    filterData() {
      return this.$store.getters['interviews/filterData']
    },
    brands() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData.brands
    },
  },
}
</script>
